html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

* {
  font-size: 1rem;
}

/* Custom. */

a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
}

* {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  &::placeholder {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
}

textarea {
  resize: vertical;
  min-height: 150px;
}

input,
textarea {
  padding: 1rem;
  border: unset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

select {
  padding: 1rem 0;
}

h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  padding-top: unset;
}

section {
  h1 {
    &:first-of-type {
      padding-top: unset;
    }
  }
  h2 {
    &:first-of-type {
      padding-top: unset;
    }
  }
  h3 {
    &:first-of-type {
      padding-top: unset;
    }
  }
  h4 {
    &:first-of-type {
      padding-top: unset;
    }
  }
  h5 {
    &:first-of-type {
      padding-top: unset;
    }
  }
  h6 {
    &:first-of-type {
      padding-top: unset;
    }
  }

  p {
    &:last-of-type {
      padding-bottom: unset;
    }
  }
}

img {
  max-width: 100%;
}
