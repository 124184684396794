/**
 * Media queries.
 */

$tablet: 768;
$laptop: 1024;
$desktop: 1248;
$widescreen: 1774;

$unit: px;

@mixin tablet {
  @media (min-width: #{$tablet}#{$unit}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop}#{$unit}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}#{$unit}) {
    @content;
  }
}

@mixin widescreen {
  @media (min-width: #{$widescreen}#{$unit}) {
    @content;
  }
}
