.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "content"
    "figure";
  grid-gap: 2rem;
  align-items: center;
  text-align: center;
  padding: 1.5rem 0;
  border-top: solid $grey-100 1px;
  border-bottom: solid $grey-100 1px;

  @include laptop {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "content figure";
    text-align: left;
    padding: 2.5rem 0;
    grid-gap: 3rem;
  }
  @include widescreen {
    grid-gap: 4rem;
  }
  &--no-border {
    border: none;
  }
  &--no-border-top {
    border-top: none;
  }
  &--title {
    display: block;
  }
  &--reverse {
    @include laptop {
      grid-template-areas: "figure content";
    }
  }

  &__layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "content"
      "figure";
    grid-gap: 2rem;
    align-items: center;
    text-align: center;
    padding: 1.5rem 0;

    @include laptop {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "content figure";
      text-align: left;
      padding: 2.5rem 0;
      grid-gap: 3rem;
    }
    @include widescreen {
      grid-gap: 4rem;
    }
  }

  &__figure {
    grid-area: figure;
  }
  &__content {
    grid-area: content;
  }
  &__title {
    color: $accent-color;
    &--center {
      text-align: center;
      padding-bottom: unset;
    }
  }
  &__list {
    margin-top: 1.5rem;
    list-style: disc inside;
  }
  &__image {
    @extend %round;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    &--natural {
      width: auto;
      margin: auto;
      display: block;
    }
  }
  &__button {
    margin-top: 2rem;
    &--multiple {
      margin: 1rem 0.5rem 0.5rem 0.5rem;
      &:first-of-type {
        margin-left: unset;
      }
      &:last-of-type {
        margin-right: unset;
      }
    }
  }
  ul {
    text-align: left;
    list-style: disc inside;
    margin-top: 1.5rem;
  }
}
