%pseudo {
  content: "";
  display: block;
}

%hover {
  transition: 0.3s opacity;
  &:hover {
    opacity: 0.75;
  }
}

%button {
  padding: 1rem 2.5rem;
  border: solid $grey-300 1px;
  color: $dark-text;
  cursor: pointer;
  font-weight: $semi-bold;
  min-width: 150px;
  text-align: center;
  transition: background 0.2s ease-in-out;
  // &:hover {
  //   background: $primary-color-dark;
  // }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

%round {
  border-radius: $round;
}

%shadow {
  box-shadow: $shadow;
}

%border {
  border-top: solid $grey-100 1px;
  border-bottom: solid $grey-100 1px;
}
