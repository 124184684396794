.button {
  @extend %button;
  @extend %round;
  @extend %hover;
  position: relative;
  padding-right: 3rem;
  padding-left: 1rem;
  &:before {
    @extend %pseudo;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    background: url("../assets/images/icons/chevron.svg") no-repeat center;
  }
  &--light {
    color: $white;
    &:before {
      background: url("../assets/images/icons/chevron-white.svg") no-repeat
        center;
    }
  }
  &--inverted {
    background: $white;
    color: $primary-color;
    &:before {
      background: url("../assets/images/icons/chevron-peach.svg") no-repeat
        center;
    }
  }
  &--full {
    width: 100%;
    margin: 0.5rem 0;
    text-align: left;
  }
  &--round {
    @extend %round;
  }
  &--pill {
    border-radius: 2.5rem;
  }
  &--secondary {
    background: $secondary-color;
    &:hover {
      background: $secondary-color-dark;
    }
  }
  &--accent {
    background: $accent-color;
    &:hover {
      background: $accent-color-dark;
    }
  }
  &--success {
    background: $success-color;
    &:hover {
      background: $success-color-dark;
    }
  }
  &--warning {
    background: $warning-color;
    &:hover {
      background: $warning-color-dark;
    }
  }
  &--error {
    background: $error-color;
    &:hover {
      background: $error-color-dark;
    }
  }
  &--information {
    background: $information-color;
    &:hover {
      background: $information-color-dark;
    }
  }
}
